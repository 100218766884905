import React, { useEffect, useRef, useState } from "react";
import * as paths from "../../../paths/index";
import { Link } from "gatsby";
import { HiChevronDown } from "react-icons/hi";
import { useBreakpointMdUp } from "../../../hooks/useBreakpoint";
import { Nav, StyledNavItem, StyledSubNav } from "./Navigation.styles";
import { trackGa, GaEventNames } from "../../../helpers/track";
import { isLinkSameDomain } from "../../../helpers/isLinkSameDomain";

enum NavMenus {
  accounts = "Accounts",
  universe = "Universe",
  insights = "Insights",
  about = "About",
}

interface NavItemProps {
  label: NavMenus;
}

interface SubNavItemProps {
  label: string;
  url: string;
  isNew?: boolean;
  isSoon?: boolean;
  active: boolean;
}

interface SubNavProps {
  open: boolean;
  items: SubNavItemProps[];
  navParentLabel: NavMenus;
}

function NavItem({ label }: NavItemProps) {
  return (
    <StyledNavItem>
      {label} <HiChevronDown />
    </StyledNavItem>
  );
}

function SubNav({ open = false, items, navParentLabel }: SubNavProps) {
  const subNavRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0");

  useEffect(() => {
    setMaxHeight(open ? subNavRef.current!["scrollHeight"] + "px" : "0");
  }, [open]);

  return (
    <StyledSubNav
      ref={subNavRef}
      className={open ? "open" : ""}
      style={{ maxHeight: maxHeight }}
    >
      {items.map((navItem) => {
        const navItemClass = (navItem: SubNavItemProps) => {
          let classes = "";
          if (navItem.isNew) classes = "new";
          if (navItem.isSoon) classes += " soon";
          return classes;
        };
        const isGatsbyLink = isLinkSameDomain(navItem.url);
        return (
          navItem.active && (
            <li
              key={navItem.label}
              onClick={(e) => {
                trackGa({
                  event: GaEventNames.navigationClick,
                  navParent: navParentLabel,
                  link_url: navItem.url,
                  link_text: navItem.label,
                });
              }}
            >
              {isGatsbyLink ? (
                <Link className={navItemClass(navItem)} to={navItem.url}>
                  {navItem.label}
                </Link>
              ) : (
                <a className={navItemClass(navItem)} href={navItem.url}>
                  {navItem.label}
                </a>
              )}
            </li>
          )
        );
      })}
    </StyledSubNav>
  );
}

interface MenuProps {
  label: NavMenus;
  menu: SubNavItemProps[];
  open: boolean;
  onEnter: (menu: NavMenus) => void;
  onClick: (menu: NavMenus) => void;
  onLeave: () => void;
}

function Menu({ label, menu, open, onEnter, onClick, onLeave }: MenuProps) {
  return (
    <li
      onMouseEnter={() => onEnter(label)}
      onMouseLeave={onLeave}
      onClick={() => onClick(label)}
    >
      <NavItem label={label} />
      <SubNav navParentLabel={label} items={menu} open={open} />
    </li>
  );
}

export interface NavigationProps {
  extraClass?: string;
}

export function Navigation({ extraClass, ...props }: NavigationProps) {
  const [navOpen, setNavOpen] = useState<NavMenus | false>(false);
  const signedIn = false;
  const aboveLaptop = useBreakpointMdUp();
  let timeout: ReturnType<typeof setTimeout> | undefined = undefined;

  const accountsMenu: Array<SubNavItemProps> = [
    {
      label: "Pension",
      url: paths.tillitSIPPProductPagePath,
      active: true,
    },
    {
      label: "Stocks & Shares ISA",
      url: paths.tillitISAProductPagePath,
      active: true,
    },
    {
      label: "General Investment Account",
      url: paths.tillitGIAProductPagePath,
      active: true,
    },

    {
      label: signedIn ? "Transfer to TILLIT" : "Transfer Concierge",
      url: signedIn ? paths.startTransferPath : paths.transfersWebPage,
      isNew: signedIn ? true : false,
      active: true,
    },
    {
      label: "Fees",
      url: paths.tillitFeesPath,
      active: true,
    },
  ];

  const universeMenu: Array<SubNavItemProps> = [
    {
      label: "The TILLIT Universe",
      url: paths.hexagonPagePath,
      active: true,
    },
    {
      label: "The Dark Universe",
      url: paths.tillitDarkUniversePath,
      active: true,
    },
    {
      label: "How does it work?",
      url: "",
      active: false,
    },
    {
      label: "Investment Committee",
      url: paths.tillitInvestmentCommitteePath,
      active: true,
    },
  ];

  const insightsMenu: Array<SubNavItemProps> = [
    {
      label: "The Insights",
      url: paths.tillitInsightsPath,
      active: true,
    },
    {
      label: "The pension gender gap",
      url: paths.tillitPensionGenderGapPath,
      active: true,
    },
    {
      label: "Jargon Flashcards",
      url: paths.tillitJargonPath,
      active: true,
    },
  ];

  const aboutMenu: Array<SubNavItemProps> = [
    {
      label: "About us",
      url: paths.tillitAboutUsPath,
      active: true,
    },
    {
      label: "TILLIT in the news",
      url: paths.tillitInTheNews,
      active: true,
    },
    {
      label: "Our culture",
      url: paths.tillitCulturePath,
      active: false,
    },
    {
      label: "Frequently Answered Questions",
      url: paths.tillitFAQs,
      active: true,
    },
    {
      label: "Contact us",
      url: paths.tillitContactPath,
      active: true,
    },
  ];

  const handleOnHover = (menu: NavMenus) => {
    if (!aboveLaptop) return null;
    setNavOpen(menu);
    clearTimeout(timeout);
  };

  const handleOnLeave = () => {
    if (!aboveLaptop) return null;
    timeout = setTimeout(() => {
      setNavOpen(false);
    }, 500);
  };

  const handleClick = (menu: NavMenus) => {
    if (aboveLaptop) return null;
    navOpen === menu ? setNavOpen(false) : setNavOpen(menu);
  };

  return (
    <Nav className={extraClass || ""} {...props}>
      <li className="primary">
        <Link
          to={paths.tillitEmployerPath}
          onClick={() => {
            trackGa({
              event: GaEventNames.navigationClick,
              navParent: "Employers",
              link_url: paths.tillitEmployerPath,
              link_text: "Employers",
            });
          }}
        >
          Employers
        </Link>
      </li>
      <Menu
        label={NavMenus.accounts}
        menu={accountsMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.accounts}
      />
      <Menu
        label={NavMenus.universe}
        menu={universeMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.universe}
      />
      <Menu
        label={NavMenus.insights}
        menu={insightsMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.insights}
      />
      <Menu
        label={NavMenus.about}
        menu={aboutMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.about}
      />
    </Nav>
  );
}

export type CustomNavProps = {
  label: string;
  url: string;
  target?: "_self" | "_blank";
};

export interface CustomNavigationProps {
  extraClass?: string;
  nav: CustomNavProps[];
}

export function CustomNavigation({
  extraClass,
  nav,
  ...props
}: CustomNavigationProps) {
  const handleClick = (label: string, url: string) => {
    trackGa({
      event: GaEventNames.navigationClick,
      navParent: label,
      link_url: url,
      link_text: label,
    });
  };

  return (
    <Nav className={extraClass || ""} {...props}>
      {nav.map((item, i: number) => {
        // the gatsby Link component cannot be used in this instance,
        // Neither <Link> nor navigate can be used for in-route navigation
        // with a hash or query parameter. If you need this behavior, you should
        // either use an anchor tag or import the @reach/router package—which Gatsby
        // already depends upon—to make use of its navigate function
        // source: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#recommendations-for-programmatic-in-app-navigation
        return (
          <li key={item.label + "-" + i}>
            <a href={item.url} target={item.target || "_blank"}>
              {item.label}
            </a>
          </li>
        );
      })}
    </Nav>
  );
}
