import { transparentize } from "polished";
import styled, { css } from "styled-components";
import { colors } from "../../../theme/theme";
import {
  ContentAlignment,
  ContentAlignments,
  VerticalAlignment,
  VerticalAlignments,
} from "../../_shared";
import { Text } from "../Slices/_shared/Text/Text";

export interface BoxContainerBox {
  $shadow: boolean | "small" | "medium" | "large";
  $textAlign: ContentAlignments;
  $vertAlign: VerticalAlignments;
  $fullWidth: boolean;
}

export const BoxContainer = styled(Text)<BoxContainerBox>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $vertAlign }) => VerticalAlignment[$vertAlign]};
  text-align: ${({ $textAlign }) =>
    $textAlign === ContentAlignment.center ? "center" : "left"};
  padding: 2rem;
  background-color: ${({ theme }) =>
    theme.theme.page === "dark"
      ? transparentize(0.95, colors.white)
      : colors.white};
  box-shadow: ${({ theme, $shadow }) => {
    if ($shadow === true) {
      return theme.shadows.medium;
    }
    if (typeof $shadow === "string") {
      return theme.shadows[$shadow];
    }
    return "none";
  }};
  border-radius: 20px;

  ${({ $shadow }) =>
    $shadow &&
    css`
      border: ${({ theme }) =>
          theme.theme.page === "dark"
            ? transparentize(0.95, colors.clouds)
            : colors.clouds}
        solid 1px;
      box-shadow: ${({ theme }) =>
        $shadow === true ? theme.shadows.medium : theme.shadows[$shadow]};
    `}
`;
