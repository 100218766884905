import styled from "styled-components";

interface ListContainerProps {
  $align: "flex-start" | "center" | "flex-end";
}

export const ListContainer = styled.ul<ListContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0;
  margin: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: ${({ $align }) => $align};
    gap: 1.5rem;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  p {
    margin: 0;
  }
`;
