import React from "react";
import { graphql } from "gatsby";
import { useBreakpointMdUp } from "../../../hooks/useBreakpoint";
import { PureTextImage } from "../../design-system/Slices/SliceTextImage/SliceTextImage";
import { Image as StyledImage } from "../../design-system/Image/Image";
import CustomRichText from "../../CustomRichText";
import SliceFooterActions from "../SliceFooterActions";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";

/**
 * @typedef {import("@prismicio/client").Content.PlatformFeesSlice} PlatformFeesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<PlatformFeesSlice>} PlatformFeesProps
 * @param { PlatformFeesProps }
 */

export interface PlatformFeesProps {
  slice: any;
  context?: any;
}

const PlatformFees = ({ slice, context, ...props }: PlatformFeesProps) => {
  if (!context || !context.hasOwnProperty("fee_tier")) {
    return null;
  }
  const feeTier = context.fee_tier;
  const content = slice.items.filter((item: any) => item.fee === feeTier);

  if (!Array.isArray(content) || !content.length) {
    return null;
  }

  const isLarge = useBreakpointMdUp();
  const hasFooterOptions = content[0].cta_options.document;
  const withShadows = slice.primary.shadows ? true : false;
  const mobImgOrder = slice.primary.mobile_image_order;

  return (
    <PureTextImage
      mobImgOrder={mobImgOrder}
      layout={slice.primary.layout}
      image={
        content[0].image.localFile?.childImageSharp ? (
          <StyledImage
            image={content[0].image.localFile?.childImageSharp}
            withShadows={withShadows}
            rounded={true}
          />
        ) : (
          <></>
        )
      }
      {...props}
    >
      {content[0].content && (
        <CustomRichText field={content[0].content.richText} context={context} />
      )}

      {hasFooterOptions &&
        content[0].cta_options.document.data.slices.length > 0 && (
          <SliceFooterActions
            slice={content[0].cta_options.document.data.slices[0]}
            align={isLarge ? "left" : "center"}
            context={context}
          />
        )}

      <RiskWarningAndDisclosures
        riskWarning={content[0].risk_warning}
        content={content[0].below_cta_content?.richText || null}
        context={context}
      />
    </PureTextImage>
  );
};

export default PlatformFees;

export const query = graphql`
  fragment PlatformFees on PrismicPlatformFeesDefault {
    id
    slice_type
    slice_label
    variation
    primary {
      layout
      mobile_image_order
      shadows
    }
    items {
      risk_warning
      fee
      below_cta_content {
        richText
      }
      content {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      image {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 492
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
            )
          }
        }
        url
      }
    }
  }
`;
