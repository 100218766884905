import React from "react";
import { ListContainer, ListItem } from "./FeaturedList.styles";

interface FeaturedListProps {
  list: string[];
  $align?: "flex-start" | "center" | "flex-end";
}

export function FeaturedList({ list, $align = "center" }: FeaturedListProps) {
  return (
    <ListContainer $align={$align}>
      {list.map((item) => (
        <ListItem>{item}</ListItem>
      ))}
    </ListContainer>
  );
}
