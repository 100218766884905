import styled, { keyframes } from "styled-components";
import { colors } from "../../../../theme/theme";
import { transparentize } from "polished";

export const TimeLineSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 2rem 0 0 0;
    margin-bottom: 3rem;
  }
`;

export const StyledTimeLineRow = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;

  &:nth-child(2n) {
    justify-content: flex-end;
  }

  &:last-of-type,
  &:last-of-type > :last-child {
    background-color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.richBlack : colors.white};
  }
`;

export const TimeLineItem = styled.div`
  width: 100%;
  padding-left: 2.5rem;
  padding-bottom: 2.5rem;

  > *:first-child {
    margin-top: 0;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: calc(50% - 6rem);
    padding: 0;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 1.25rem 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0 0 3.25rem 0;
  }
`;

export const TimeLineRail = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  left: 0;
  bottom: 0;
  width: 2px;
  transform: translateX(1px);
  background-color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors["grey-300"] : colors["magenta-50"]};
  z-index: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    left: 50%;
    transform: translateX(-1px);
  }
`;

export const StyledFilledTimeLineRail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 2px;
  background-color: ${colors.magenta};
  transform: translateX(1px);
  z-index: 1;

  ${({ theme }) => theme.breakpoints.up("md")} {
    left: 50%;
    transform: translateX(-1px);
  }
`;

const pulse = keyframes`
0% {
  box-shadow: 0 0 0 0 ${colors["magenta-400"]}
}
70% {
  box-shadow: 0 0 0 16px ${transparentize(0.85, colors["magenta-400"])}
}
100% {
  box-shadow: 0 0 0 0 ${transparentize(1, colors.white)}
}
`;

interface StyledDotProps {
  active: boolean;
}

export const StyledDot = styled.div<StyledDotProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, active }) =>
    theme.theme.page === "dark"
      ? !active
        ? colors.richBlack
        : colors.white
      : colors.white};
  background-color: ${({ active }) =>
    active ? colors.magenta : colors["magenta-100"]};
  z-index: ${({ theme }) => theme.zIndex.other};
  box-shadow: 0 0 0 0 ${colors.white};
  animation-name: ${({ active }) => active && pulse};
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 125ms;
  transition: background-color 125ms linear;
  transform: translateX(-1.2rem) translateY(0px);

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 60px;
    height: 60px;
    left: 50%;
    transform: translateX(-30px) translateY(-2px);
  }
`;
