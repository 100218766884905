import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { SliceContainer } from "../components/design-system/Slices/SliceContainer/SliceContainer";
import { components } from "../components/slices";
import { Layout } from "../components/layout/Layout";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { Seo } from "../components/Seo";
import { darkTheme } from "../theme/theme";

const allowPreview = process.env.GATSBY_ALLOW_PREVIEW == "true";

interface PageProps {
  data: any;
}

function PageTemplate({ data }: PageProps) {
  if (!data) return null;
  const doc = data.prismicPage.data;

  return (
    <Layout
      header={doc.hide_header !== true}
      altTheme={doc.page_mode ? darkTheme : undefined}
    >
      <SliceContainer>
        <SliceZone slices={doc.slices} components={components} />
      </SliceContainer>
    </Layout>
  );
}

export const PageTemplateWithPreview = withPrismicPreview(PageTemplate);

export default allowPreview ? PageTemplateWithPreview : PageTemplate;

export const Head = ({ data }: PageProps) => {
  const { url } = data.prismicPage;
  const { disallow_indexing, page_title, page_description } =
    data.prismicPage.data;
  const hasImage = data.prismicPage.data.og_image.localFile !== null;
  const gatsbyImageData =
    hasImage &&
    data.prismicPage.data.og_image.localFile?.childImageSharp.gatsbyImageData;

  return (
    <Seo
      noIndex={disallow_indexing}
      title={page_title}
      description={page_description}
      url={url}
      image={
        hasImage
          ? {
              src: gatsbyImageData.images.fallback.src,
              height: gatsbyImageData.height,
              width: gatsbyImageData.width,
            }
          : {
              src: `/static/og-images/${data.prismicPage.url.replaceAll(
                "/",
                ""
              )}.png`,
              height: 630,
              width: 1200,
            }
      }
    />
  );
};

export const query = graphql`
  query ($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      url
      type
      data {
        disallow_indexing
        page_title
        page_description
        og_image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1200)
            }
          }
        }
        hide_header
        page_mode
        slices {
          ...HeaderDefault
          ...HeaderCustomNav
          ...FeaturedInLogosDefault
          ...TextImageTextImage
          ...TextImageImageText
          ...TextRowDefault
          ...TextRowFullWidth
          ...SpacerDefault
          ...FeaturedHeroDefault
          ...FeaturedHeroTube
          ...FeaturedHeroImage
          ...FeaturedHeroFeatured
          ...FeaturedHeroBackground
          ...FeaturedContentSelectorDefault
          ...ThreeColumnTextDefault
          ...TextOnImageDefault
          ...TextOnImageFullWidth
          ...TypeformEmbedDefault
          ...TypeformEmbedPopup
          ...TypeformEmbedSlide
          ...TypeformEmbedFullPage
          ...TestimonialsDefault
          ...TestimonialsSingle
          ...TestimonialsDual
          ...FaqsDefault
          ...FaqsContained
          ...ThreeColumnTextDefault
          ...ThreeColumnTextBoxed
          ...ThreeColumnTextDefaultWithVideo
          ...ThreeColumnTextBoxedWithVideo
          ...FeaturedInsightsDefault
          ...InPageNavigationDefault
          ...DeepLinkDefault
          ...FeaturedVideoDefault
          ...TextRowWithBorder
          ...FeaturedFundsDefault
          ...FeaturedContentCarouselDefault
          ...InTheNewsSliceDefault
          ...InTheNewsSliceFeatured
          ...VideoAskLandscape
          ...VideoAskPortrait
          ...TextVideoVideoText
          ...TextVideoTextVideo
          ...TimelineDefault
        }
      }
    }
  }
`;
