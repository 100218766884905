import React from "react";
import { H2 } from "../../Typography/headings/Headings";
import {
  ContentContainer,
  ContentContainerColumn,
  NarrowVideoAskContainer,
  NarrowVideoAskPlayer,
  VideoAskContainer,
  VideoAskPortraitContainer,
  WideVideoAskPlayer,
} from "./VideoAsk.styles";

interface SliceVideoAskProps {
  layout?: "landscape" | "portrait";
  videoId: string;
  videoMinHeight: number;
  videoWidth: number;
  videoAlign?: "left" | "right";
  mobVideoAlign?: "top" | "bottom";
  heading?: string;
  children?: JSX.Element | React.ReactNode | string;
  fullScreen?: boolean;
  justVideo?: boolean;
  rounded?: boolean;
  withShadows?: boolean;
  hiddenFields?: string;
}

export function SliceVideoAsk({
  layout = "landscape",
  videoId,
  videoMinHeight,
  videoWidth,
  videoAlign = "left",
  mobVideoAlign = "top",
  heading,
  children,
  rounded = true,
  withShadows = true,
  fullScreen = false,
  justVideo = false,
  hiddenFields,
  ...props
}: SliceVideoAskProps) {
  return (
    <VideoAskContainer {...props}>
      {layout === "landscape" ? (
        <LandscapeVideoAskLayout
          heading={heading}
          videoId={videoId}
          rounded={rounded}
          withShadows={withShadows}
          fullScreen={fullScreen}
          videoMinHeight={videoMinHeight}
          videoWidth={videoWidth}
          justVideo={justVideo}
          hiddenFields={hiddenFields}
        >
          {children}
        </LandscapeVideoAskLayout>
      ) : (
        <PortraitVideoAskLayout
          heading={heading}
          videoId={videoId}
          videoAlign={videoAlign}
          mobVideoAlign={mobVideoAlign}
          rounded={rounded}
          withShadows={withShadows}
          videoMinHeight={videoMinHeight}
          videoWidth={videoWidth}
          justVideo={justVideo}
          hiddenFields={hiddenFields}
        >
          {children}
        </PortraitVideoAskLayout>
      )}
    </VideoAskContainer>
  );
}

type LandscapeVideoAskLayoutProps = Pick<
  SliceVideoAskProps,
  | "children"
  | "heading"
  | "videoId"
  | "rounded"
  | "withShadows"
  | "fullScreen"
  | "justVideo"
  | "videoMinHeight"
  | "videoWidth"
  | "hiddenFields"
>;

function LandscapeVideoAskLayout({
  heading,
  videoId,
  videoMinHeight,
  videoWidth,
  rounded,
  withShadows,
  fullScreen,
  justVideo,
  hiddenFields,
  children,
}: LandscapeVideoAskLayoutProps) {
  const urlQuery = `?${fullScreen ? "fullscreen" : ""}${
    justVideo ? "&justvideo" : ""
  }${hiddenFields ? hiddenFields : ""}`;

  return (
    <>
      <ContentContainer>
        {heading && <H2 center>{heading}</H2>}
        {children}
      </ContentContainer>

      <WideVideoAskPlayer
        src={`https://www.videoask.com/${videoId}${urlQuery}`}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        $rounded={rounded}
        $shadows={withShadows}
        $minHeight={videoMinHeight}
        $Width={videoWidth}
      />
    </>
  );
}

interface PortraitVideoAskLayoutProps extends SliceVideoAskProps {}

function PortraitVideoAskLayout({
  heading,
  videoId,
  videoMinHeight,
  videoWidth,
  videoAlign = "left",
  mobVideoAlign = "top",
  justVideo,
  rounded,
  withShadows,
  hiddenFields,
  children,
}: PortraitVideoAskLayoutProps) {
  const urlQuery = `?fullscreen${justVideo ? "&justvideo" : ""}${
    hiddenFields ? hiddenFields : ""
  }`;

  return (
    <VideoAskPortraitContainer>
      <ContentContainerColumn>
        {heading && <H2 center>{heading}</H2>}
        {children}
      </ContentContainerColumn>

      <NarrowVideoAskContainer $mobAlign={mobVideoAlign} $align={videoAlign}>
        <NarrowVideoAskPlayer
          src={`https://www.videoask.com/${videoId}${urlQuery}`}
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          $rounded={rounded}
          $shadows={withShadows}
          $minHeight={videoMinHeight}
          $Width={videoWidth}
        />
      </NarrowVideoAskContainer>
    </VideoAskPortraitContainer>
  );
}
