const replacementStringKeys = [
  "employerName",
  "employerFirstAnniversary",
  "employerReferralCode",
  "feeTier",
];

type replacementKeyType = typeof replacementStringKeys[number];

type replacementStrings = {
  [key in replacementKeyType]: string;
};

export interface PrismicSegment {
  type: string;
  text: string;
  spans?: any[];
  direction?: string;
}

const getReplacementValue = (
  replacements: replacementStrings,
  replacementKey: replacementKeyType
) => {
  return replacements[replacementKey]!;
};

export const prismicRichTextTemplateStringHelper = (
  replacements: replacementStrings,
  prismicSegments: PrismicSegment[]
) => {
  return prismicSegments.map(prismicSegmentTemplateStringHelper(replacements));
};

export const prismicSegmentTemplateStringHelper =
  (replacements: replacementStrings) =>
  (prismicSegment: PrismicSegment): PrismicSegment => {
    if (prismicSegment.type === "image") return prismicSegment;
    const regexText = new RegExp(`{{(${replacementStringKeys.join("|")})}}`);
    let workingText = prismicSegment.text;
    let spans = prismicSegment.spans;
    while (regexText.test(workingText)) {
      const regexResult = regexText.exec(workingText);

      const replacementKey = regexResult?.[1]! as replacementKeyType;

      const start = regexResult?.index!;
      const end = start + replacementKey.length;

      const replacementValue = getReplacementValue(
        replacements,
        replacementKey
      );
      const adjustmentLength =
        replacementValue.length - replacementKey.length - 4;

      spans = spans
        ? spans.map((span) => {
            const newSpan = { ...span };
            if (newSpan.start > start) {
              newSpan.start += adjustmentLength;
            }
            if (newSpan.end >= end) {
              newSpan.end += adjustmentLength;
            }
            return newSpan;
          })
        : undefined;

      workingText = workingText.replace(regexText, replacementValue);
    }

    spans = spans
      ? spans.map((span) => {
          if (span.type !== "hyperlink") {
            return span;
          }

          return {
            ...span,
            data: {
              ...span.data,
              url: span.data.url.replace(
                /{{employerReferralCode}}/g,
                replacements.employerReferralCode
              ),
            },
          };
        })
      : undefined;

    return {
      ...prismicSegment,
      spans,
      text: workingText,
    };
  };
