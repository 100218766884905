import React from "react";
import { BoxContainer } from "./Box.styles";
import {
  ContentAlignment,
  ContentAlignments,
  VerticalAlignment,
  VerticalAlignments,
} from "../../_shared";

export interface BoxProps {
  children: JSX.Element | React.ReactNode;
  $align?: ContentAlignments;
  $vAlign?: VerticalAlignments;
  shadow?: boolean | "small" | "medium" | "large";
  fullWidth?: boolean;
}

export function Box({
  shadow = true,
  $align = ContentAlignment.center,
  $vAlign = VerticalAlignment.center,
  children,
  fullWidth = false,
  ...props
}: BoxProps) {
  return (
    <BoxContainer
      $textAlign={$align}
      $vertAlign={$vAlign}
      $shadow={shadow}
      $fullWidth={fullWidth}
      {...props}
    >
      {children}
    </BoxContainer>
  );
}
