import React from "react";
import { useTheme } from "styled-components";
import { Container, Padding } from "../../../Container/Container";
import { ContentContainer, ColumnsContainer } from "./BoxedColumns.styles";
import { Box } from "../../../Box/Box";
import { VerticalAlignment, VerticalAlignments } from "../../../../_shared";

export interface PureBoxedColumnsProps {
  content?: JSX.Element | React.ReactNode | string;
  boxes: JSX.Element[] | React.ReactNode[] | string[];
  $vAlign?: VerticalAlignments;
  actions?: JSX.Element | React.ReactNode | string;
}

/**
 * @todo add variant for testimonial display in a wider width without the box shadow.
 */
export function PureBoxedColumns({
  content,
  boxes,
  $vAlign = VerticalAlignment.center,
  actions,
}: PureBoxedColumnsProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container
      padding={Padding.both}
      className={isDark ? "dark-background" : ""}
    >
      {content && <ContentContainer>{content}</ContentContainer>}
      <ColumnsContainer>
        {boxes.map((box, i) => {
          return (
            <Box key={i} $vAlign={$vAlign} fullWidth>
              {box}
            </Box>
          );
        })}
      </ColumnsContainer>
      {actions && <>{actions}</>}
    </Container>
  );
}
