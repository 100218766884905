import styled from "styled-components";
import { sizes } from "../../../theme/theme";
import { colors } from "../../../theme/theme";

interface HeroInnerProps {
  $align: "flex-start" | "center" | "flex-end";
}

export const HeroInner = styled.div<HeroInnerProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ $align }) => $align};
  align-items: center;
  z-index: 1;
`;

export const MediaContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  .gatsby-image-wrapper img {
    width: auto;
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    ${({ theme }) => theme.breakpoints.up("md")} {
      object-position: center bottom;
    }
  }
`;

export interface ContentContainerProps {
  $align: "flex-start" | "center" | "flex-end";
  $isDark?: boolean;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  * {
    color: ${({ $isDark }) => ($isDark ? colors.white : "inherit")};
  }

  > h1.xl-heading {
    margin-bottom: 0.625rem;
  }

  .block-img {
    img {
      width: auto;
      margin-bottom: 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 100%;
    align-items: ${({ $align }) =>
      $align === "center" ? "center" : "flex-start"};
    max-width: ${({ $align }) => ($align === "center" ? "768px" : sizes.sm)};
    text-align: ${({ $align }) => ($align === "center" ? "center" : "left")};

    p {
      max-width: ${sizes.sm};
    }
  }
`;
