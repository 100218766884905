import numeral from "numeral";
import "numeral/locales/en-gb";

numeral.locale("en-gb");

export function day(value: string | number) {
  if (!value) {
    return null;
  }
  const nth = (d: number) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return value + nth(Number(value));
}

export function month(value: string | number) {
  if (!value) {
    return null;
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[Number(value) - 1];
}

function parseNumber(value: string | number) {
  return typeof value === "string" ? parseFloat(value) : value;
}

export function percent(value: string | number) {
  const floatValue = parseNumber(value);
  return numeral(floatValue).format("0,0.00%");
}
