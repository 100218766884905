import React from "react";
import { graphql } from "gatsby";
import { SimpleHero } from "../../Hero/Simple/SimpleHero";
import SliceFooterActions from "../SliceFooterActions";
import { H1Xl } from "../../design-system/Typography/headings/Headings";
import { TubeHero } from "../../Hero/Tube/TubeHero";
import { ImageHero } from "../../Hero/Image/ImageHero";
import { Image, RawImage } from "../../design-system/Image/Image";
import { FeaturedHero as PureFeaturedHero } from "../../Hero/Featured/FeaturedHero";
import { ImageOrdering, ImagePosition } from "../../_shared";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import { employerTextHelper } from "../../../helpers/employerHelper";
import CustomRichText from "../../CustomRichText";
import { BackgroundHero } from "../../Hero/Background/BackgroundHero";
import { FeaturedList } from "../../design-system/FeaturedList/FeaturedList";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedHeroSlice} FeaturedHeroSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedHeroSlice>} FeaturedHeroProps
 * @param { FeaturedHeroProps }
 */

export interface FeaturedHeroProps {
  slice: any;
  context?: any;
}

const FeaturedHero = ({ slice, context, ...props }: FeaturedHeroProps) => {
  const variant = slice.variation;
  const title = slice.primary.title ? (
    <CustomRichText field={slice.primary.title.richText} context={context} />
  ) : (
    <></>
  );
  const content = slice.primary.description ? (
    <CustomRichText
      field={slice.primary.description.richText}
      context={context}
    />
  ) : (
    <></>
  );
  const hasFooterOptions = slice.primary.cta_options.document;
  let actions = (isCenter: boolean = false) => {
    return (
      <>
        {hasFooterOptions &&
          slice.primary.cta_options.document.data.slices.length > 0 && (
            <SliceFooterActions
              slice={slice.primary.cta_options.document.data.slices[0]}
              align="center"
              mdUpAlign={isCenter ? "center" : "left"}
              context={context}
            />
          )}
      </>
    );
  };
  const riskWarningAndDisclosure = (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning}
      content={slice.primary.below_cta_content?.richText || null}
      context={context}
    />
  );
  const ImageObjectFit = slice.primary.image_cover ? "cover" : "contain";

  if (variant === "background") {
    return (
      <BackgroundHero
        heading={employerTextHelper({
          text: slice.primary.heading,
          context,
        })}
        contentAlign={slice.primary.hero_alignment}
        isDark={slice.primary.dark_background}
        fullHeight={slice.primary.hero_height}
        image={
          slice.primary.desktop_background.localFile?.childImageSharp ? (
            <RawImage
              image={slice.primary.desktop_background.localFile.childImageSharp}
            />
          ) : null
        }
        mobileImage={
          slice.primary.mobile_background.localFile?.childImageSharp ? (
            <RawImage
              image={slice.primary.mobile_background.localFile.childImageSharp}
            />
          ) : null
        }
      >
        {slice.primary.description && (
          <CustomRichText
            field={slice.primary.description.richText}
            context={context}
          />
        )}
        {slice.items.length > 0 && (
          <FeaturedList
            list={slice.items.map((item: any) => (
              <>
                {item.featured_list_item && (
                  <CustomRichText
                    field={item.featured_list_item.richText}
                    context={context}
                  />
                )}
              </>
            ))}
            $align={slice.primary.hero_alignment}
          />
        )}
        {actions(slice.primary.hero_alignment === "center" ? true : false)}
        {riskWarningAndDisclosure}
      </BackgroundHero>
    );
  }

  if (variant === "image") {
    return (
      <ImageHero
        heading={employerTextHelper({
          text: slice.primary.heading,
          context,
        })}
        fullHeight={slice.primary.hero_height}
        imagePosition={slice.primary.image_position as ImagePosition}
        mobileImagePosition={
          slice.primary.mobile_image_position as ImageOrdering
        }
        image={
          slice.primary.featured_image.localFile?.childImageSharp ? (
            <Image
              withShadows={slice.primary.image_shadow}
              rounded={slice.primary.image_rounded_corners}
              backgroundObjectFit={ImageObjectFit}
              image={slice.primary.featured_image.localFile.childImageSharp}
            />
          ) : null
        }
      >
        {slice.primary.description && (
          <CustomRichText
            field={slice.primary.description.richText}
            context={context}
          />
        )}
        {actions()}
        {riskWarningAndDisclosure}
      </ImageHero>
    );
  }

  if (variant === "featured") {
    return (
      <PureFeaturedHero
        heading={employerTextHelper({
          text: slice.primary.heading,
          context,
        })}
        image={
          slice.primary.featured_image.localFile?.childImageSharp ? (
            <Image
              image={slice.primary.featured_image.localFile.childImageSharp}
              withShadows={false}
              rounded={false}
            />
          ) : null
        }
        videoUrl={slice.primary.featured_video.url || null}
        videoPoster={
          slice.primary.featured_image.localFile?.childImageSharp
            .gatsbyImageData.images.fallback.src || null
        }
        doNotCropVideo={slice.primary.do_not_crop_video}
      >
        {slice.primary.description && (
          <CustomRichText
            field={slice.primary.description.richText}
            context={context}
          />
        )}
        {actions()}
        {riskWarningAndDisclosure}
      </PureFeaturedHero>
    );
  }

  actions = () => {
    return (
      <>
        {hasFooterOptions &&
          slice.primary.cta_options.document.data.slices.length > 0 && (
            <SliceFooterActions
              slice={slice.primary.cta_options.document.data.slices[0]}
              align={"center"}
              context={context}
            />
          )}
      </>
    );
  };

  if (variant === "tube") {
    return (
      <TubeHero
        title={title}
        content={content}
        callToActions={actions()}
        belowCTA={riskWarningAndDisclosure}
        {...props}
      />
    );
  }

  return (
    <SimpleHero
      title={
        slice.primary.title ? (
          <CustomRichText
            field={slice.primary.title.richText}
            components={{
              heading1: ({ children }) => <H1Xl center>{children}</H1Xl>,
            }}
            context={context}
          />
        ) : (
          <></>
        )
      }
      content={content}
      fullHeight={slice.primary.hero_height}
      callToActions={actions()}
      belowCTA={riskWarningAndDisclosure}
      {...props}
    />
  );
};

export default FeaturedHero;

export const query = graphql`
  fragment FeaturedHeroDefault on PrismicFeaturedHeroDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      hero_height
      description {
        richText
      }
      title {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment FeaturedHeroTube on PrismicFeaturedHeroTube {
    id
    slice_label
    slice_type
    variation
    primary {
      description {
        richText
      }
      title {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment FeaturedHeroImage on PrismicFeaturedHeroImage {
    id
    slice_label
    slice_type
    variation
    primary {
      heading
      hero_height
      mobile_image_position
      image_position
      image_shadow
      image_rounded_corners
      image_cover
      featured_image {
        alt
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 1080
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      description {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment FeaturedHeroBackground on PrismicFeaturedHeroBackground {
    id
    slice_label
    slice_type
    variation
    items {
      featured_list_item {
        richText
      }
    }
    primary {
      dark_background
      heading
      hero_height
      hero_alignment: content_alignment
      desktop_background {
        alt
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              width: 1920
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      mobile_background {
        alt
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 1080
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      description {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment FeaturedHeroFeatured on PrismicFeaturedHeroFeatured {
    id
    slice_label
    slice_type
    variation
    primary {
      heading
      description {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
      below_cta_content {
        richText
      }
      featured_image {
        alt
        url
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              width: 1080
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      featured_video {
        url
        id
        type
      }
      do_not_crop_video
    }
  }
`;
